import { notify } from "ui";
import { useIsLoggedIn } from "@web/hooks/auth/useIsLoggedIn";
import { useViewerContext } from "@web/hooks/contexts/useViewerContext";
import { useEffect } from "react";

type Props = {
  children: any;
};

export function RedirectIfLoggedInContainer({ children }: Props) {
  const { isLoggingIn } = useViewerContext();
  const isLoggedIn = useIsLoggedIn();

  // Note: because of React strict mode, this will fire twice on localhost
  // See https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  useEffect(() => {
    // Check isLoggingIn b/c we don't want to show this notif if the user is currently
    //  in the process of logging in
    if (isLoggedIn && !isLoggingIn) {
      notify({
        description: "Redirecting to the home page...",
        message: "You are already logged in",
        type: "info",
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  }, [isLoggedIn, isLoggingIn]);

  return children;
}
