import { UrlParam } from "shared";
import { isProd } from "@web/utils/env/isProd";
import { getUrlParam } from "@web/utils/url/getUrlParam";

export function consoleLogErrorIfNotProd(e: Error) {
  if (isProd() && getUrlParam(UrlParam.EnableErrorLogs) !== "1") {
    return;
  }
  // eslint-disable-next-line no-console
  console.error(e);
}
