import {
  getFirebaseAnalytics,
  getFirebaseAuth,
} from "@web/utils/firebase/initializeFirebase";
import { signInWithCustomToken } from "firebase/auth";
import { setUserId } from "firebase/analytics";

export async function firebaseLogin(token: string): Promise<void> {
  const userCredential = await signInWithCustomToken(getFirebaseAuth(), token);
  if (userCredential.user) {
    setUserId(getFirebaseAnalytics(), userCredential.user.uid);
  }
}
