import styles from "@web/pages/login/css/LoginOrRegisterPage.module.css";
import { PageBody, Body1Medium, ColorClass } from "ui";
import { LoginOrRegisterEmailForm } from "@web/components/login/LoginOrRegisterEmailForm";
import { LoginOrRegisterNonceForm } from "@web/components/login/LoginOrRegisterNonceForm";
import { useState } from "react";
import { LoginOrRegister } from "@web/types/LoginOrRegister";
import { useQueryParams } from "@web/hooks/useQueryParams";
import { RegisterCreateAccount } from "@web/components/login/RegisterCreateAccount";
import { RedirectIfLoggedInContainer } from "@web/components/auth/RedirectIfLoggedInContainer";
import { GoogleLoginButton } from "@web/components/auth/GoogleLoginButton";
import { Maybe, UrlParam } from "shared";
import { useRouter } from "next/router";
import { PageWithHeaderAndFooter } from "@web/components/containers/PageWithHeaderAndFooter";

type Props = {
  loginOrRegister: LoginOrRegister;
};

function LoginOrRegisterContent({ loginOrRegister }: Props) {
  const [showNonceForm, setShowNonceForm] = useState<boolean>(false);

  const form = showNonceForm ? (
    <LoginOrRegisterNonceForm loginOrRegister={loginOrRegister} />
  ) : (
    <LoginOrRegisterEmailForm
      loginOrRegister={loginOrRegister}
      onSuccess={() => setShowNonceForm(true)}
    />
  );
  return (
    <div className={styles.form}>
      {form}
      <div className={styles.separator}>
        <div className={styles.separatorLine} />
        <Body1Medium
          className={styles.separatorText}
          colorClass={ColorClass.Secondary}
          textTransform="uppercase"
        >
          Or
        </Body1Medium>
        <div className={styles.separatorLine} />
      </div>
      <div className={styles.googleLoginButtonContainer}>
        <GoogleLoginButton />
      </div>
    </div>
  );
}

function Container({
  children,
  loginOrRegister,
}: {
  children: any;
  loginOrRegister: LoginOrRegister;
}) {
  const headerText = loginOrRegister === "login" ? "Sign In" : "Get Started";

  return (
    <PageWithHeaderAndFooter>
      <PageBody header={headerText}>{children}</PageBody>
    </PageWithHeaderAndFooter>
  );
}

export function LoginOrRegisterPage({ loginOrRegister }: Props) {
  const { pathname } = useRouter();
  const params = useQueryParams();

  const nonce = params[UrlParam.Nonce] as Maybe<string>;
  const googleIdToken = params[UrlParam.GoogleIdToken] as Maybe<string>;
  if ((nonce != null || googleIdToken != null) && pathname === "/register") {
    return (
      <RedirectIfLoggedInContainer>
        <Container loginOrRegister={loginOrRegister}>
          <div className={styles.form}>
            <RegisterCreateAccount
              createAccountNonce={nonce}
              googleIdToken={googleIdToken}
            />
          </div>
        </Container>
      </RedirectIfLoggedInContainer>
    );
  }

  return (
    <RedirectIfLoggedInContainer>
      <Container loginOrRegister={loginOrRegister}>
        <LoginOrRegisterContent loginOrRegister={loginOrRegister} />
      </Container>
    </RedirectIfLoggedInContainer>
  );
}
